@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import '../../assets/styles/global/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/styles/global/mixins';

.section--video {
  &.video-content-overlay {
    .video-item__wrapper {
      @include media-breakpoint-down(sm) {
        padding-bottom: 154%;
      }
    }
  }

  .video-item {
    &__wrapper {
      padding-bottom: 45.25%;

      //&::after {
      //  content: '';
      //  display: block;
      //  position: absolute;
      //  z-index: 2;
      //  top: 0;
      //  left: 0;
      //  width: 100%;
      //  height: 100%;
      //  background-color: rgba($black, 0.2);
      //}

      @include media-breakpoint-down(lg) {
        padding-bottom: 56.25%;
      }
    }

    &__content-overlay {
      padding: 4rem;
      width: 100%;

      @include media-breakpoint-down(xl) {
        padding: 4rem 2rem;
      }

      @include media-breakpoint-down(lg) {
        padding: 1.5rem 1rem;
      }

      @include media-breakpoint-down(sm) {
        .btn {
          width: 100%;
        }
      }

      .row {
        --bs-gutter-x: 5rem;

        @include media-breakpoint-down(xl) {
          --bs-gutter-x: 2rem;
        }
      }
    }
  }
}
